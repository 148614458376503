<template>
  <section title="">
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-card-body>
            <b-row>
              <b-col md="10">
                <h1> Registro de Pedido </h1>
              </b-col>
              <b-col md="0,5">
                <h1> ID : </h1>
              </b-col>
              <b-col md="1">
                <h1>{{ dados.id }}</h1>
              </b-col>
              <b-col md="12">
                <h6>Registro detalhado do pedido Finalizado para fins de consulta</h6>
              </b-col>
              <b-col
                md="12"
                style="margin-top: 3%; margin-bottom: 2%"
              >
                <h4>PRODUTOS:</h4>
              </b-col>
              <b-col md="12">
                <b-row>

                  <b-col md="2">
                    <h6>ID :</h6>
                  </b-col>
                  <b-col md="6">
                    <h6>NOME :</h6>
                  </b-col>
                  <b-col md="2">
                    <h6>CODIGO :</h6>
                  </b-col>
                  <b-col md="2">
                    <h6>PREÇO :</h6>
                  </b-col>
                </b-row>
                <b-row
                  v-for="(hv, key) in dados.order_items"
                  :key="key"
                >
                  <b-col md="2">
                    <div>{{ hv.products.id }}</div>
                  </b-col>
                  <b-col md="6">
                    <div>{{ hv.products.name }}</div>
                  </b-col>
                  <b-col md="2">
                    <div>{{ hv.products.code }}</div>
                  </b-col>
                  <b-col md="2">
                    <div>{{ hv.products.price }}</div>
                  </b-col>
                  <b-col
                    style="margin-bottom: 1%"
                  />
                </b-row>
                <b-row
                  style="margin-top: 2%"
                >
                  <b-col md="2">
                    <h4>Quantidade :</h4>
                  </b-col>
                  <b-col md="6">
                    <div> <h4>{{ quantity }}</h4></div>
                  </b-col>
                  <b-col md="2">
                    <h4>Valor Total :</h4>
                  </b-col>
                  <b-col md="2">
                    <div> <h4>{{ dados.totalProducts }}</h4> </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>

export default {
  data() {
    return {
      url: 'pedidos/tabela',
      dados: {},
      quantity: 0,
    }
  },
  created() {
    this.BuscarDados()
    this.$loading(true)
  },
  methods: {
    async BuscarDados() {
      const { data: dados } = await this.$http.get(`orders/find/${this.$route.params.id}`)
      this.dados = dados
      dados.order_items.forEach(item => {
        this.quantity += Number(item.qunatity)
      })
      // eslint-disable-next-line prefer-destructuring
      this.$loading(false)
    },
  },
}
</script>
